var login = (function () {
    var that = this,
        link = '/login',
        form = '[data-id]';

    function start() {
        $(document).on('click', form+' button', function(){
            form = $(this).parents('[data-id]').attr('data-id');
            form_data = $('[data-id="' + form + '"]').serializeArray();
            jQuery.ajax({
                url: link,
                type: 'POST',
                data: form_data,
                success: function(data){
                    if(data.usertoken) {
                        saveToken(data.usertoken);
                    }
                    window.location = data.url;
                },
                error: function(data){

                    var jsonData = data.responseJSON;
                    var error = '';

                    if(jsonData.error) {
                        error = jsonData.error.email;
                    } else {
                        error = jsonData.email || jsonData.errors.email || jsonData.password
                    }

                    console.log(jsonData);

                    $('.alert-danger.main-page').removeAttr('hidden').html(error);
                }
            });
            // $.post(link,$('[data-id="' + form + '"]').serializeArray())
            //     .done(function (data) {
            //         var promise = new Promise(function(resolve, reject){
            //             if(data.usertoken) {
            //                 saveToken(data.usertoken);
            //             }
            //             $('[data-id="' + form + '"]' + ' input').removeClass('error');
            //             resolve();
            //         })
            //         promise.then(function(){
            //             if(data.url) {
            //                 window.location = data.url;
            //             }
            //         });


            //     })
            //     .fail(function (data) {
            //         let jsonData = data.responseJSON;
            //         let error = '';
            //         if(jsonData.error) {
            //             error = jsonData.error;
            //         } else {
            //             error = jsonData
            //         }

            //         let error_message = jsonData.errors.email;
            //         let alertBlock = $('.alert-danger.main-page');

            //         $.each(error,function(i,val){
            //             $('[data-id="' + form + '"]' +' input[name="'+i+'"]').addClass('error');
            //             alertBlock.empty();
            //             alertBlock.append(error_message);
            //             alertBlock.removeAttr('hidden');
            //             // $('.alert-danger.main-page').css('visibility', 'visible');
            //         });
            //         console.error('Fail load data');
            //     })
            return false;
        })
    }

    return {
        init: function () {
            start();
        },
    }
}());

if($('#login').length!=0) {
    login.init()
}

function saveToken(token) {
    localStorage.setItem('authToken', token);
    localStorage.setItem('authTokenExpiretion', Date.now() + 14400000);
}