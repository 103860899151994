
/* height of first window*/


height();


function height(){
  if($('.header-slider').length > 0) {
      $('.header-slider').height(window.innerHeight - 90);
  }
}

/*футер к полу */
/*setTimeout(function() {
    var height = jQuery(window).innerHeight() - jQuery('html').innerHeight();
    if(height > 0){
        jQuery('footer').css('margin-top',height + 60);
    }
},10);*/

/* scroll contact*/
$('[href="#gmap"]').click(function(){
  $('body').animate({ scrollTop: $('#gmap').offset().top },1000);
  return false;
})

if($('.main__header__login').length > 0){
  $('.main__header__login').width($('.name-logout').outerWidth() + 30);
}

$('.lang').find('li.active a').removeAttr('href');
$('.lang ul').prepend($('.lang').find('li.active'));

/* end */


$('#login').keydown(function(event){
  if(event.keyCode == 13) {
    $(this).find('button').click();
  }
});

$(window).keydown(function(event){
  if(event.keyCode == 13) {
    event.preventDefault();
    return false;
  }
});



$(window).resize(function () {
  height();
})


/* фиксированое меню*/
// $(window).scroll(function (scroll) {
//     var scroll = $(window).scrollTop(),
//         nav = $('header');
//     scroll > 100 ? nav.addClass('fixed') : nav.removeClass('fixed');
// })


/* табы */
$('[data-tab][data-parent]').click(function () {
    var this_ = $(this),
        id = this_.attr('data-parent'),
        visP = this_.attr('data-tab');
    if (!this_.hasClass('active')) {
        $('[data-parent="' + id + '"][data-tab]').removeClass('active');
        $(this_).addClass('active');
        $('[data-parent="' + id + '"][data-tabs]').hide();
        $('[data-tabs="' + visP + '"]').show();
    }
})

/* акардион */

$('[data-acc="accordion"]').click(function (event) {
    var this_ = $(this);
    if (!this_.hasClass('open')) {
        $('[data-acc="accordion"]').removeClass('open');
        this_.addClass('open');
    }else{this_.removeClass('open');}
    return false;
})

/* for upload files */
$(document).on('click','.updateFileCertificate,button.note',function(){
  var this_ = $(this);
  $('.updateCerf, .hidInfoCerf').removeClass('active');
  this_.parents('.updateCerf').next('.hidInfoCerf').addClass('active');
  return false;
})

$(document).on('click','.button.upload',function(){
  $(this).next('.uploadImages').click();
})


$(document).on('click','.hidInfoCerf .close', function(){
  $('.updateCerf, .hidInfoCerf').removeClass('active');
  return false;
})

/* end upload files */

/* slider of main page*/
$('.our_agency_slider').slick({
  slidesToShow: 4,
  slidesToScroll: 2,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ]
});

$('.cert .slick-slide').click(function() {
    var IDX = $(this).attr("data-slick-index");
    switch (IDX){
        case "0":
            window.open("/files/office/license.pdf", "_blank", "toolbar=no,scrollbars=yes,status=no,titlebar=no,location=no,resizable=yes,width=800,height=480");
            break;
        case "1":
            window.open("/files/office/MLC.pdf", "_blank", "toolbar=no,scrollbars=yes,status=no,titlebar=no,location=no,resizable=yes,width=800,height=480");
            break;
        case "2":
            window.open("/files/office/ISO9001_2015.pdf", "_blank", "toolbar=no,scrollbars=yes,status=no,titlebar=no,location=no,resizable=yes,width=800,height=480");
            break;
        case "3":
            window.open("/files/office/LISCR.pdf", "_blank", "toolbar=no,scrollbars=yes,status=no,titlebar=no,location=no,resizable=yes,width=800,height=480");
            break;
    }
});

/* end main slider */




  


/* select flag*/
function selectFlag(){
  var select = $('.flag-select').parents('table').find('th[data-name="flag"]').data('type');
  $('.flag-select').each(function(){
    var option = '',
        val = $(this).find('option:selected').val(),
        t = 0;
    for (var i = 0; i < Object.keys(select).length; i++) {
      t = i + 1;
      if(val != '0'){
        if(val == t){
          option += '<li class="active"><span data-val="'+ t+'" class="flag flag-'+ select[i].flag + '"></span><span class="nameFlag">' + select[i].name + '</span></li>';
        }else{
          option += '<li><span data-val="'+ t +'" class="flag flag-' + select[i].flag  + '"></span><span class="nameFlag">' + select[i].name + '</span></li>';
        }
      }else{
        val = '-1';
        option += '<li class="active"><span data-val="0">'+ $(this).find('option:first-child').text() +'</span></li>';
      }
    }
    $(this).append('<ul class="ul-select">' + option + '</ul>');
  });
}

selectFlag();


/* select all */

function selectList(flag){
  var selectAll;

  if(flag){
    selectAll = 'select[data-selectAll]';
  }
  else{
    selectAll = 'select[data-selectAll][data-new-rank]';
  }

  $(selectAll).each(function(){

    /* получаем json данные */

    var str = $(this).attr('data-rank-tree'),
        jsonObj = $.parseJSON('[' + str + ']'),
        count = jsonObj[0].length,
        temp = listLi = '',
        parentDRT = $(this).next('.data-rank-tree');
        countL = 1;
    
    /* преобразуем их в html разметку */
    var list = jsonObj[0].map(function(itemL) {
        listLi += listSearch(itemL,temp,count);
    });

    /* добавляем в нужный блок */
    parentDRT.append(listLi);

    $(this).removeAttr('data-new-rank');

    $('[data-parentp]:not([data-parentMain])').each(function(){
      var this_ = $(this),
          attr = this_.attr('data-parentp'),
          parentList = this_.parent('.data-rank-tree'),
          item = parentList.find('[data-parentl='+ attr +'] > ul');
      this_.appendTo(item);
    })


    var valueS = $(this).find("option:selected").val();
    var flag =  true;

    parentDRT.find('[data-vall]').each(function(){
      if($(this).attr('data-vall') == valueS){
        parentDRT.before('<span class="option__name">' + $(this).text() + '</span>');
        $(this).addClass('active');
        flag = false;
      }
    })

    if(flag){
      if($(this).find('option:first-child').val().length > 0){
        $(this).prepend('<option value selected></option>');
        parentDRT.before('<span class="option__name">' + $(this).parents('table').find('[data-selectname]').attr('data-selectname') + '</span>');
      }
      else{
        parentDRT.before('<span class="option__name">' + $(this).find('option:first-child').text() + '</span>');
      }
    }

    $('[data-vall]').click(function(even){
      var this_ = $(this),
          val = this_.attr('data-vall'),
          parent = this_.parents('.data-rank-tree'),
          parentS = parent.parent().find('select');

      parent.find('[data-vall]').removeClass('active');
      this_.addClass('active');
      parent.prev().text(this_.text());
      parentS.find('option').removeAttr('selected');
      parentS.find('option[value="'+ val +'"]').attr('selected','selected');
      parentS.change(); 

      if(parent.hasClass('active')){
        parent.hide().removeClass('active');
        even.stopPropagation();
      }
    });
  });
}

$(document).on('click','.option__name',function(){
  var divSelect = $(this).next('.data-rank-tree');

  $('.data-rank-tree').hide().removeClass('active');
  divSelect.addClass('active').show();
})

$(document).on('touchstart','.option__name',function(){
  var divSelect = $(this).next('.data-rank-tree');

  $('.data-rank-tree').hide().removeClass('active');
  divSelect.addClass('active').show();
})

$(document).on('click',function (event) {
  if(!$(event.target).parents('.data-rank-tree').length > 0 
      && !$(event.target).hasClass('data-rank-tree') && !$(event.target).hasClass('option__name')){
    $('.data-rank-tree').hide().removeClass('active');
  }
})


selectList(true);

/* рекурсия */

function listSearch(item,temp,count){

  if(item._children != undefined){
    
    item._children.map(function(list){
      temp = listSearch(list,temp,count);
    })

    if(item.parent == 0) {

      temp += '<ul class="main__list mail__list_' + countL + '"><li data-parentl="'+ item.id + '" data-parentMain="'+ item.id + '" data-parentp="'+ item.parent + '"><span> '+ item.name + '</span><ul></ul></li></ul>';
      countL++;
      return temp;
    }
    else{
      temp += '<li data-parentl="'+ item.id + '" data-parentp="'+ item.parent + '"><span>'+ item.name + '</span><ul></ul></li>';
      return temp;
    }
  }else{
    temp += '<li data-valL="' + item.id + '" data-parentp="' + item.parent + '"><a>' + item.name + '</a></li>';
    return temp;
  }

}


/* флаги */

$(document).on('click','.ul-select li',function(){
  var val = $(this).find('span').data('val'),
      parent = $(this).parents('.flag-select');

  parent.find('li').removeClass('active');
  $(this).addClass('active');
  parent.find('option').removeAttr('selected');
  parent.find("option[value='" + val + "']").attr('selected','selected');
})


$(document).on('click','.ul-select',function(){
  $(this).toggleClass('active');
  if($(this).hasClass('active')){
    $(this).scrollTop($(this).find('.active').position().top - 30);
    $(this).parent().prepend('<span class="closeFlag"></span>');
  }else{
    $(this).parent().find('.closeFlag').remove();
  }
})

$(document).on('click','.closeFlag',function(){
  $(this).parent().find('.ul-select').removeClass('active');
  $(this).remove();
})


/* регистрация добавления поля */

$(document).on('click','.addTR', function(){

  var maxRow = $(this).prev('[data-name="department"]').find('tbody tr').length || 0;
  if(maxRow < 5 && maxRow !=  undefined){

    var this_ =         $(this),
        parent =        this_.prev('table'),
        name =          parent.data('name') || 'column',
        column_input =  parent.find('th[data-name]'),
        column_action = parent.find('th[data-action]'),
        min           = parent.find('th[data-min]')
        content =       parent.children('tbody'),
        item =          content.find('tr');

    content.append('<tr class="updateCerf" data-count="' + item.length + '"></tr>'); /* для сертификатов*/

    for (var i = 0; i < column_input.length; i++) {
        var placeholder = column_input.eq(i).data('placeholder') || '',
            pattern = column_input.eq(i).data('pattern') || '',
            required = column_input.eq(i).data('required') || '',
            name2 = column_input.eq(i).data('name') || '';
            _autocomplete = column_input.eq(i).data('autocomplete') || '',
            _class = column_input.eq(i).data('class') || '';
            date = column_input.eq(i).data('date') || 'false',
            dateEnd = column_input.eq(i).data('time') || 'false',
            select = column_input.eq(i).data('type') || 'false';
            flagS = column_input.eq(i).data('flag') ;
            minA = column_input.eq(i).data('min');
            maxA = column_input.eq(i).data('max');
            rankTree = column_input.eq(i).attr('data-rank-tree');
            validClass = column_input.eq(i).data('class');
            formType = column_input.eq(i).data('form') || 'input';
        if(select != 'false'){
          var option = [],
              li = [],
              flag,
              classS = false;
          for (var t = 0; t < Object.keys(select).length; t++) {
            var vals = Object.keys(select).map(function(key) {
              return select[key];
            });
            if(flagS){
              option += '<option value='+ t +'>' + select[t] + '</option>';
            }else{
              if(name2 == 'subtype') {
                option += '<option value="'+ vals[t] +'">' + vals[t] + '</option>';
              }
              else {
                if(name2 == 'country_id') {
                  if(t == 0){
                    option += '<option value>Select a country</option>';
                  }
                  option += '<option value="'+ (t + 1) +'">' + vals[t] + '</option>';
                }
                else{
                  if(vals[t].flag != '' && vals[t].flag != undefined){
                    option += '<option value='+ t +'>' + vals[t].name + '</option>';
                    if(t == '0'){
                      li += '<li><span data-val="'+ (t + 1) +'" class="flag flag-'+ vals[t].flag + '"></span><span class="nameFlag">' + vals[t].name + '</span></li>';
                      classS = true;
                    }
                    else{
                      li += '<li><span data-val="'+ (t + 1) +'" class="flag flag-'+ vals[t].flag + '"></span><span class="nameFlag">' + vals[t].name + '</span></li>';
                    }
                  }else{
                    option += '<option value='+ (t + 1) +'>' + select[(t + 1)] + '</option>';
                  }
                }
              }
            }
          }
          if(!classS){
            if(rankTree != undefined){
            content.find('tr:last-child').append('<td data-select="select"><select name="'+name+'['+item.length+']['+name2+']" data-selectall data-rank-tree data-new-rank>\
              '+ option + ' \
              </select><div class="data-rank-tree"></div></td>');
            content.find('tr:last-child [data-new-rank]').attr('data-rank-tree', rankTree);
            selectList(false);
            }else{
              content.find('tr:last-child').append('<td data-select="select"><select name="'+name+'['+item.length+']['+name2+']">\
              '+ option + ' \
              </select></td>');
            }
            if(name2 == 'subtype') {
              $('select[name="'+name+'['+item.length+']['+name2+']"]').select2({
                  tags: true,
                  placeholder: "Select a type"
              });
            }
            if(name2 == 'country_id'){
              $('select[name="'+name+'['+item.length+']['+name2+']"]').select2({
                  placeholder: "Select a country"
              });
            }
          }
          else{
            content.find('tr:last-child').append('<td class="flag-select"><select name="'+name+'['+item.length+']['+name2+']">\
              '+ option + ' \
              </select><ul class="ul-select"><li class="active"><span>'+ $('[data-flagname]').attr('data-flagname') +'</span></li>' + li + '</ul></td>');
          }
        }else{
          if(date == 'false'){
            if(formType == 'textarea'){
                content.find('tr:last-child').append('<td><textarea data-date="'+ date +'" name="'+name+'['+item.length+']['+name2+']" placeholder="'+placeholder + (minA != '' ? '" minlength="' + minA : '') + (maxA != '' ? '" maxlength="' + maxA : '') + '" data-new="' + true  + (required ? '" required="' + required :'') + '"rows=1' +'/>');
            }
            else{
                content.find('tr:last-child').append('<td><input type="text" data-date="'+ date +'" name="'+name+'['+item.length+']['+name2+']" placeholder="'+placeholder+(minA != '' ? '" minlength="' + minA : '') + (maxA != '' ? '" maxlength="' + maxA : '') + '" class="' + validClass + (required ? '" required="' + required :'') +'" data-new="' + true +'"/>');
            }
          }else{
            if(dateEnd != 'end'){
            content.find('tr:last-child').append('<td data-date="date"><input type="text" data-time="start" name="'+name+'['+item.length+']['+name2+']" placeholder="'+placeholder+'" pattern="'+pattern+ (_class !=='' ? '" class="'+_class : '') +'" data-new="' + true + (required ? '" required="' + required :'') + (_autocomplete ? '" autocomplete="' + _autocomplete :'') +'"/></td>');
            }
            else{
              content.find('tr:last-child').append('<td data-date="date"><input type="text"  data-time="end" name="'+name+'['+item.length+']['+name2+']" placeholder="'+placeholder+'" pattern="'+pattern+ (_class !=='' ? '" class="'+_class : '') +'" data-new="' + true + (required ? '" required="' + required :'')+ (_autocomplete ? '" autocomplete="' + _autocomplete :'') +'"/></td>');
            }
          }
        }
    }

    for (var i = 0; i < column_action.length; i++) {
        var text = column_action.eq(i).data('text') || '',
            style_class = column_action.eq(i).data('class') || '';
        content.find('tr:last-child').append('<td><button class="'+style_class+'" onclick="return false">'+text+'</button></td>');
    }

    //dateSelect();
  }
    $( ".datepicker" ).datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: 'dd.mm.yy',
        onChangeMonthYear: function(year, month, inst) {
            if (inst.currentYear != inst.drawYear || inst.currentMonth != inst.drawMonth) {
                $(this).datepicker("setDate", new Date(year, month - 1, inst.selectedDay));
            }
        }
    });
    $("input[type=text]").focusout(function() {
        var uname = this.value.toUpperCase();
        var pname = toTitleCase(this.value);
        if(this.name.indexOf('place')>0){
            $(this).val(pname);
        }else{
            $(this).val(uname);
        }

    });
});


$(document).on('click','.alert .close', function(){
  $(this).parent('.alert').fadeOut();
})


// Kick everything off with the target image

$('.add-input').click(function(){
    var this_ = $(this),
        newInput = this_.prev('input');
    this_.before(newInput.clone().val(''));
})


/* close modal window*/
$('.overlay').click(function(){
    $(this).fadeOut();
    $('[data-over],.plan-modal,.img-cropp,[data-modLog],.year__plan,.packet-window').fadeOut();
})

/* end close */

$('.log-In').click(function(){
    $('.enter[data-over],.overlay').fadeIn();
})


/* смена зп*/
$('.salary dd:not(.close-s),.readiness dd:not(.close-s)').click(function(){
    var this_ = $(this);
    if(!this_.hasClass('hidden') && !this_.hasClass('close-s')){
        this_.next().removeClass('hidden');
        this_.addClass('hidden');
    }
}) 

$('.save-s').click(function(){
    var this_ = $(this),
    parent = this_.parent();
    parent.addClass('hidden');
    parent.prev().removeClass('hidden');
})




/* slider profile crewing*/
$('.img-slider-list a').click(function(){
  var a = $(this).attr('href');
  $('.img-slider-crewing img').attr('src',a);
  return false;
}) 

if($(window).width() > 767){
  sliderD();
}else{
  sliderM()
}

function sliderD() {
  $('.slider-button').click(function(){
    var body = $('.img-slider-list ul li:first-child'),
        button = $(this),
        count = $('.img-slider-list li').length,
        top = body.css('margin-top'),
        mt = 165,
        mas = [];
      
    top = top == "auto" ? 0 : (parseInt(top) * -1);
    for (var i = 0; i <= count; i++) {
      mas[i] = 165 * i;
    }

    
    if(button.hasClass('next-img')){
      for (var i = 0; i <= count; i++) {
        if(mas[i] > top && mas[i] != top){
          if(top != mas[count - 2]){
            mt = mas[i];
          }
          console.log(mas[i],top);
          break;
        }else{
          mt = 0;
        }
      }
      body.animate({marginTop: - mt},500);
    }else{
      if(top != 0) {
        mt = top - 165;
        body.animate({marginTop: - mt},500);
      }
    }
  })
}

function sliderM() {
  var count = $('.img-slider-list li').length;
  $('.img-slider-list ul').width(parseInt(count * $('.slider-block').width()));
  $('.img-slider-list li').width(parseInt($('.slider-block').width()));
  $('.slider-button').click(function(){
    var body = $('.img-slider-list ul li:first-child'),
        button = $(this),
        left = body.css('margin-left'),
        mt = parseInt($('.slider-block').width()),
        widthM = parseInt($('.slider-block').width()),
        mas = [];

    body.css('margin-top','0');
    left = left == "auto" ? 0 : (parseInt(left) * -1);
    for (var i = 0; i <= count; i++) {
      mas[i] = widthM * i;
    }
  
    
    if(button.hasClass('next-img')){
      for (var i = 0; i <= count; i++) {
        if(mas[i] > left && mas[i] != left){
          if(left != mas[count - 2]){
            mt = mas[i];
          }
          break;
        }else{
          mt = 0;
        }
      }
      body.animate({marginLeft: - mt},500);
    }else{
      if(left != 0) {
        mt = left - widthM;
        body.animate({marginLeft: - mt},500);
      }
    }
  })
}


/* end slider */



/* date  input*/

/* заполняем даты */
function dateSelect(){
  
  var date = new Date();
      // year = date.getFullYear() + 20;
      year = 2035;
      month = date;
      mas = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      input = '';
  $('.date-time').each(function(){

    $(this).find('.year,.month').html('');

    for (var i = year; i >= 1945; i--) {
      $(this).find('.year').append('<option value="' + i + '">' + i + '</option>');
    }
    for (var i = 0; i < 12; i++) {
      $(this).find('.month').append('<option value="' + (i + 1) + '">' + mas[i] + '</option>');
    }



    getD(31,$(this).find('.month'));

    if($(this).prev('input').val().length > 0){
      input = $(this).prev('input').val().split('.');
    }else{
      var nD = new Date();
      $(this).prev('input').val(nD.getDate() + '.' + ( nD.getMonth()  + 1 ) + '.'+ nD.getFullYear());
      input = ["" + nD.getDate() +"","" + ( nD.getMonth()  + 1 )  + "","" + nD.getFullYear() + ""];
    }
    

    var t1 = '';

    if(input.length > 1){
      for (var i = 0; i < 3; i++) {
        switch(i){
          case 0: 
            $(this).find('.date').children('option').removeAttr('selected'); 
            t1 = input[i].match(/[1-9]*\d/g);
            tt = t1[1];
            if( t1[1] == undefined ){ tt = t1[0]; }
            $(this).find('.date').children('option').each(function(){
              if( $(this).val() == tt ){
                $(this).attr('selected','selected');
              } 
            })
          break;
          case 1: 
            $(this).find('.month').children('option').removeAttr('selected'); 
            $(this).find('.month').children('option').each(function(){
              t1 = input[i].match(/[1-9]*\d/g);
              tt = t1[1];
              if( t1[1] == undefined ){ tt = t1[0]; }
              if( $(this).val() == tt ){
                $(this).attr('selected','selected');
              }
            })
          break;
          case 2: 
            $(this).find('.year').children('option').removeAttr('selected'); 
            $(this).find('.year').children('option').each(function(){
              if( $(this).val() == input[i] ){
                $(this).attr('selected','selected');
              } 
            })
          break;
        }
      }
    }
  })
}

dateSelect();


/* выводит дату */
function getD(day,this_){
    var this_ = this_ || null;
    var time = 'body';
    if(this_ != null) {
      var time = this_.parents('.date-time').find('.date');
    }
    var val = $(time).find('option:selected').val();
    $(time).children('option').each(function() {$(this).remove();});

    for (var i = 0; i < day; i++) {
      (i + 1) == val ? $(time).append('<option value="' + (i + 1) + '" selected>' + (i + 1) + '</option>') : $(time).append('<option value="' + (i + 1) + '">' + (i + 1) + '</option>');
    }
}

/* ловил изменения */

$(document).on('change','.month, .year', function(){
  var this_ = $(this);
  if( this_.hasClass('month') ){
    var month = this_.val(),
        year  = this_.next('select').val();
  }else{
    var year = this_.val(),
        month = this_.prev('select').val();
  }
  getDaneNews(month, year, this_);
})

/* получает дату */
function getDaneNews(month,year,this_){
  var date = new Date(year,month);
    date.setDate(0);
    getD(date.getDate(),this_);
}

/* end date */ 

/* запись даты в бд*/
$(document).on('change','.date,.month,.year',function(){
    var input = $(this).parent('.date-time'),
        text = '';
    input.children('select').each(function(item){
        switch(item){
            case 0: text += $(this).children('option:selected').val(); break;
            case 1: text += '.' + $(this).children('option:selected').val(); break;
            case 2: text += '.' + $(this).children('option:selected').val(); break;
        }
    })
    input.prev('input').val(text);
})

$(document).on('click','td[data-date="date"]',function(){
  var this_ = $(this);
      this_.addClass('active');
})

/*end date */

/*updateFileCertificate */ 

$(document).on('click','.updateFileCertificate', function(){
    var this_ = $(this);
        parents = this_.parents('tr'),
        data = this_.parents('tbody').data();
        url = data.url || 'url',
        paramName = data.paramname || 'images',
        maxFilesize = data.maxfilesize || '1',
        inputName = data.inputname || 'images',
        acceptedFiles = data.acceptedfiles || 'image/*',
        parentD =  data.parent || '',
        type = data.type || '',
        count = parents.data('count');

    $('.updateCerf, .hidInfoCerf').removeClass('active');

    if(!parents.hasClass('updateFile')){
        parents.addClass('updateFile');
        parentD += '[' + count +']';
        parents.after('<tr class="hidInfoCerf active">\
            <td colspan="' + parents.children('td').length + '">\
                    <div class="uploadImages img-cert dropzone" id="file-img"\
                        data-url="'+ url +'" \
                        data-paramName="'+ paramName +'" \
                        data-maxFilesize="'+ maxFilesize +'" \
                        data-inputName="'+ inputName +'"\
                        data-acceptedFiles="'+ acceptedFiles +'"\
                        data-type="'+ type +'"\
                        data-parent="'+ parentD +'"> \
                    </div>\
                <button class="close"></button>\
            </td>\
        </tr>');
    }else {
      parents.next('.hidInfoCerf').addClass('active');
    } 

    $(document).on('click','.hidLabel .dropzone', function(e){
      e.preventDefault();
      return false;
    })

    
    if(!this_.hasClass('noDrop')){
      dropzoneInit(this_);
    }

    // this_.addClass('noDrop');

    return false;
});

/* end */

/*year__plan */
$('#year__plan').click(function(){
  $('.year__plan,.overlay').fadeIn();
})


/* end */





/* modal window fleet*/
$(document).on('click','a[data-modal]', function(){
    $('.modal-window.wages-scale,.overlay').fadeIn();
})

/* end modal*/ 

/* summary app.form*/
$('.pchecker').change(function(){
    var this_ = $(this);
    if(this_.prop('checked') && $('#privacy_agree').prop('checked') && $('#cookie_agree').prop('checked')){
        $('button.btn-x').removeAttr('disabled');
    }else{
        $('button.btn-x').attr('disabled','disabled');
    }
});

$('.save').click(function(){
  $('.summary-modal,.overlay').fadeIn();
})
$('.btn-save').click(function(){
  $('#save-btn').click();
})

$('.close-modal').click(function(){
  $('.summary-modal,.overlay,.modal-window,.img-cropp,[data-modlog],.profile-modal,.modal').fadeOut();
  return false;
})


$('.change-plan').click(function(){
  $('.plan-modal,.overlay').fadeIn();
})

$('.menu__mobile__visible').click(function(){
  $('.mobile__menu').toggleClass('menu__show');
    /*if($('header .menu').find('.close').length == 0){
      $('header .menu').append('<span class="close"></span>');
    }*/
})

$(document).on('click','.mobile__menu .close', function(){
  $('.mobile__menu').toggleClass('menu__show');
})

if($('#Rookie').prop('checked')){
  $('#svg-2 path').css('fill','#FFDB79');
}
if($('#SeaDog').prop('checked')){
  $('#svg-3 path').css('fill','#FF6C1A');
}

if($('#Skilled').prop('checked')){
  $('#svg-4 path').css('fill','#FFBB51');
}

$(document).on('change', '#Rookie' ,function(){
  if($(this).prop('checked')){
    $('#svg-2 path').css('fill','#FFDB79');
  }else{
    $('#svg-2 path').css('fill','#D3D3D3');
  }
})

$(document).on('change', '#Skilled' ,function(){
    if($(this).prop('checked')){
      $('#svg-4 path').css('fill','#FFBB51');
    }else{
      $('#svg-4 path').css('fill','#C6C6C6');
    }
})

$(document).on('change', '#SeaDog' ,function(){
    if($(this).prop('checked')){
    $('#svg-3 path').css('fill','#FF6C1A');
  }else{
    $('#svg-3 path').css('fill','#BABABA');
  }
})



/* valid form*/

$(document).on('submit','form',function(event){
  var this_ = $(this),
      required = 0;
  this_.find('input:not([data-time]):not(.select2-search__field):not(#photo-upload):not(#cropp-upload)').each(function(){
    var input = $(this),
        min = input.attr('minlength'),
        requiredA = input.attr('required'),
        minOld = input.attr('min'),
        maxOld = input.attr('max');
    if((input.val().length >= min && requiredA != undefined) || (input.val().length > 0 && requiredA != undefined)){
      if(minOld <= parseInt(input.val()) && maxOld >= parseInt(input.val())) {
        input.removeClass('error');
      }
      else{
        if(input.attr('required') != undefined && minOld != undefined && maxOld != undefined){
          input.addClass('error');
          required = 1;
        }
      }
    }
    else{
      if(input.attr('required') != undefined){
        input.addClass('error');
        required = 1;
      }
    }
  })

  this_.find('select[data-selectall]').each(function(){
    var select = $(this);
    
    if(select.val() != null) {
      if(select.val().length != 0){
        select.removeClass('error');
      }
      else{
        select.addClass('error');
        required = 1;
      }
    }
    else{
      select.addClass('error');
      required = 1;
    }
  })

  this_.find('[data-time]').each(function(){
    if($(this).val().length > 0) {
    var input = $(this).val().split('.'),
        time = $(this).attr('data-time'),
        data = new Date(),
        y = data.getFullYear(),
        m = data.getMonth(),
        d = data.getDate(),
        dateS = new Date(input[2],input[1] - 1,input[0]), /* дата с input */
        dateJ = new Date(y,m,d); /* действующая дата */ 
        dateB = new Date(y - 18,m,d);


      if(time == 'start' && !$(this).hasClass('no-valid') && !$(this).attr('disabled')){
        if(dateS > dateJ){
          $(this).next('.date-time').children('select').each(function(){
            $(this).addClass('error');
            required = 1;
          })
          $(this).parents('[data-date]').addClass('active');
        }else{
          $(this).next('.date-time').children('select').each(function(){
            $(this).removeClass('error');
          })
        }

        timeE = $(this).parents('[data-date]').next('[data-date]').find('[data-time]').val().split('.');
        dateE = new Date(timeE[2],timeE[1] - 1,timeE[0]);

        if(dateE < dateS){
          $(this).parents('[data-date]').next('[data-date]').children('select').each(function(){
            $(this).parents('[data-date]').addClass('error');
            required = 1;
          });

          $(this).next('.date-time').children('select').each(function(){
            $(this).parents('[data-date]').addClass('error');
            required = 1;
          });
        }
      }


      if(time == 'end' && !$(this).hasClass('no-valid') && !$(this).attr('disabled')){
        
        if(dateS < dateJ){
          $(this).next('.date-time').children('select').each(function(){
            $(this).addClass('error');
              required = 1;
          })
          $(this).parents('[data-date]').addClass('active');
        }else{
          $(this).next('.date-time').children('select').each(function(){
            $(this).removeClass('error');
          })
        }
      }

      if(time == 'date_of_birth'){
        if(dateB < dateS){
          $(this).next('.date-time').children('select').each(function(){
            $(this).addClass('error');
              required = 1;
          })
        }
        else{
          $(this).next('.date-time').children('select').each(function(){
            $(this).removeClass('error');
          })
        }
      }
    }
  })

  if(required){
    $('.modal-window.packet-window,.overlay').fadeOut();
    event.preventDefault();
  }
})


/* end valid */

/*copy link*/

$(document).on('click','[data-clipboard]',function(){

  event.preventDefault();
    var loc = location.origin;
    var input = document.getElementById('clipboard');

    copyToClipboard(input);

  return false;
})


function copyToClipboard(elem) {
    
    // select the content
    var currentFocus = document.activeElement;
    elem.focus();
    elem.setSelectionRange(0, elem.value.length);
    
    // copy the selection
    var succeed;
    try {
        succeed = document.execCommand("copy");
    } catch(e) {
        succeed = false;
    }
    return succeed;
}
    

/* end */

/* превью pdf*/ 

$(document).on('click','.pdf .dz-preview:not(.error) , .img-cert .img',function(){
  var this_ = $(this),
      children = this_.children('.dz-details'),
      title = children.find('img').attr('alt');
    if(children.length > 0){
      if(title.match(/[.pdf]+$/) == null){
        src = location.origin + '/files/certificate/pdf_preview/' + title.replace(/[.](.+)/,'') + '.pdf';
      }else{
        src = location.origin + '/files/certificate/' + title;
      }
    }
    else{
      var link = this_.attr('alt');
      if(link.match(/[.pdf]+$/) == null){
        src = location.origin + '/files/certificate/pdf_preview/' + link.replace(/[.](.+)/,'') + '.pdf';
      }else{
        src = location.origin + '/files/certificate/' + link;
      }
    }
    
    window.open(src);
})



$(document).on('click','[data-login]', function(e){
  $('[data-modLog],.overlay').fadeIn();
  e.preventDefault();
})


if($("#view_profile_tariff").length > 0){
    $('#seaman_id').click(function(e){
        e.preventDefault();
        $('.modal-window.packet-window,.overlay').fadeIn();
    })
    $('#proceed').click(function(){
      $('#seaman_id').parents('form').submit();
    })
}


function removeVacancies(event) {
  var form = $(event).attr('href');
  $('#delete_vacancye,.overlay').fadeIn();
    $('#del_Vac').off().click(function(){
      $(event).parent('form').submit();
  });

  return false;
}


/* ввод только цифр*/ 


$(document).on('keypress','.numberInput',function(e) {
    e = e || event;

    if (e.ctrlKey || e.altKey || e.metaKey) return;

    var chr = getChar(e);

    if (chr == null) return;

    if (chr < '0' || chr > '9') {
      return false;
    }
})

 function getChar(event) {
  if (event.which == null) {
    if (event.keyCode < 32) return null;
    return String.fromCharCode(event.keyCode) // IE
  }

  if (event.which != 0 && event.charCode != 0) {
    if (event.which < 32) return null;
    return String.fromCharCode(event.which) // остальные
  }

  return null; // специальная клавиша
}


/* getCookie */

if(getCookie('ecrewing') == undefined){
    $('body').append('<div class="cookie"> \
    In order to give you the best experience, our website uses cookies. \
    By continuing to use this site,you do accept to use of cookies on your\
    device as described in our <a href="/privacy/cookie">cookie policy</a> \
    <br><br>\
    <button class="btn">Accept and Continue</button><br></div>');

}


$(document).on('click','.cookie .btn',function(){
  $('.cookie').remove();
  setСookie('ecrewing', 'Cookie2old', (new Date).getTime() + (2 * 365 * 24 * 60 * 60 * 1000));
})

function getCookie(name) {
  var matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}


function setСookie(name, value, expires, path, domain, secure) {
  document.cookie = name + "=" + escape(value) +
                    ((expires) ? "; expires=" + (new Date(expires)) : "") +
                    ((path) ? "; path=" + path : "") +
                    ((domain) ? "; domain=" + domain : "") +
                    ((secure) ? "; secure" : "");
}




$(document).on('click','[data-delete]',function(){
  $('.modal-window.packet-window,.overlay').fadeIn();
  $('#del_ser').attr('data-delete-id',$(this).next().attr('data-id'));
})

$('#del_ser').click(function(){
  $('.modal-window.packet-window,.overlay').fadeOut();
  $('.delete[data-id="' + $(this).attr('data-delete-id') + '"').click();
})

/* filter mobile */
$(document).on('click','.filter__title',function(){
  if($(window).width() < 767){
    $(this).next('.filter__mobile').toggle();
  }
})


$("#dox").submit(function(e){
    var trig = false;
    $("#dox input").each(function () {
        if (~$(this)[0].name.indexOf('expir') && $(this).val() != '') {
            var parts = $(this).val().split(".");
            var d1 = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
            if (d1 < (new Date())) {
                trig = true;
                $(this).addClass('invalid');
                $(this)[0].scrollIntoView({block: "end", behavior: "smooth"});
            }

        } else {
            if (~$(this)[0].name.indexOf('issue')) {
                if ($(this).val() != '') {
                    var parts = $(this).val().split(".");
                    var d1 = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                    if (d1 > (new Date())) {
                        trig = true;
                        $(this).addClass('invalid');
                        $(this)[0].scrollIntoView({block: "end", behavior: "smooth"});
                    }
                } else {
                    trig = true;
                    $(this).addClass('invalid');

                    $(this)[0].scrollIntoView({block: "end", behavior: "smooth"});
                }
            }
            ;

            if (!$(this)[0].checkValidity() && $(this)[0].name != '_token') {
                $(this).addClass('invalid');
                console.log($(this)[0].name);
                trig = true;
            }


        }
    });
    if(trig == false){
        $("#dox").submit
    }else {
        return false;
    }
});
$("#app-form").submit(function(e){
    var trig = false;
    $("#dox input").each(function () {
        if (~$(this)[0].name.indexOf('expir') && $(this).val() != '') {
            var parts = $(this).val().split(".");
            var d1 = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
            if (d1 < (new Date())) {
                trig = true;
                $(this).addClass('invalid');
                $(this)[0].scrollIntoView({block: "end", behavior: "smooth"});
            }

        } else {
            if (~$(this)[0].name.indexOf('issue')) {
                if ($(this).val() != '') {
                    var parts = $(this).val().split(".");
                    var d1 = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                    if (d1 > (new Date())) {
                        trig = true;
                        $(this).addClass('invalid');
                        $(this)[0].scrollIntoView({block: "end", behavior: "smooth"});
                    }
                } else {
                    trig = true;
                    $(this).addClass('invalid');

                    $(this)[0].scrollIntoView({block: "end", behavior: "smooth"});
                }
            }
            ;

            if (!$(this)[0].checkValidity() && $(this)[0].name != '_token') {
                $(this).addClass('invalid');
                console.log($(this)[0].name);
                trig = true;
            }


        }
    });
    if(trig == false){
        $("#dox").submit
    }else {
        return false;
    }
});

function toTitleCase(str) {
    return str.replace(/(?:^|\s)\w/g, function(match) {
        return match.toUpperCase();
    });
}